import * as React from "react";
import Title, { TitleVariant } from "../Title";

interface AgencyCard {
  intent: string;
  hook: string;
  icon: React.ReactNode;
}

const AgencyCard: React.FC<AgencyCard> = ({ intent, hook, icon, children }) => (
  <section className="flex flex-col py-4 mt-10 lg:flex-row">
    <div className="mr-14">
      <div className="text-center">{icon}</div>
      <p className="text-base text-center lg:text-xl font-poppins">{intent}</p>
    </div>
    <div>
      <Title
        variant={TitleVariant.h3}
        text={hook}
        className="mt-8 text-center lg:text-left"
      />
      {children}
    </div>
  </section>
);

export default AgencyCard;
