import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import { useLayout } from "../components/Layout/layout-context";
import Section from "../components/Section";
import AgencyCard from "../components/AgencyCard";
import TeamMember from "../components/TeamMember";
import Button, { ButtonVariant } from "../components/Button";

// markup
const AgencePage = () => {
  const [setShowModal] = useLayout();

  return (
    <Layout
      title="L’agence Donut Panic"
      metaTitle="Agence d’UX Design responsable"
      metaTitleSuffix="Donut Panic"
      description="Donut Panic, agence d’ux design spécialisé dans le design responsable, nous accompagnons nos clients dans la réussite de leurs projets"
      banner="lg:bg-header-pattern"
    >
      <section className="flex flex-col py-8 mt-10 lg:flex-row lg:justify-center">
        <div className="flex flex-col justify-center">
          <div className="flex items-center">
            <StaticImage
              src="../images/icons/donut-eaten.png"
              alt="eaten donut"
            />
            <p>Depuis 5 ans</p>
          </div>
          <div className="flex items-center justify-end lg:justify-start">
            <div className="hidden h-10 lg:inline-block">
              <StaticImage
                src="../images/icons/donut-eaten.png"
                alt="eaten donut"
              />
            </div>
            <p>Plus de 70 clients</p>
            <div className="h-10 lg:hidden">
              <StaticImage
                src="../images/icons/donut-eaten2.png"
                alt="eaten donut"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center lg:ml-4">
          <div className="flex items-center">
            <StaticImage
              src="../images/icons/donut-eaten.png"
              alt="eaten donut"
            />
            <p>500 étudiants formés</p>
          </div>
          <div className="flex items-center justify-end lg:justify-start">
            <div className="hidden h-10 lg:inline-block">
              <StaticImage
                src="../images/icons/donut-eaten.png"
                alt="eaten donut"
              />
            </div>
            <p>40 000 heures de cours</p>
            <div className="lg:hidden">
              <StaticImage
                src="../images/icons/donut-eaten2.png"
                alt="eaten donut"
              />
            </div>
          </div>
        </div>
      </section>

      <Section title="Qui sommes-nous ?">
        <p className="mt-8">
          Depuis sa création en 2016, METACOM a bien grandi et nous aussi, nos
          préoccupations ont changé, nos valeurs ont évolué. Suite à ce constat
          nous avions le désir de créer une marque qui nous ressemble, une
          marque qui reflète notre capacité à aider nos clients à rationaliser
          leur activité et à mettre leur énergie là où cela compte vraiment.
          Elle reflète aussi notre envie d’enchanter notre monde et ceux de nos
          clients, partenaires et collaborateurs en y injectant une bonne dose
          de plaisir et d’autodérision. <br />
          <br />
          C’est ainsi que nous avons imaginé la recette DONUT PANIC AGENCY, nous
          avons sélectionné pour vous les meilleurs ingrédients de l’expérience
          client, selon vos envies plusieurs topping sont disponibles : Création
          et Refonte de site internet, Optimisation du parcours client… ( une
          expérience personnalisée selon vos besoins ) <br />
          <br />
          Et pour les gourmands, nous avons concocté DONUT PANIC DESIGN. <br />
          Une délicieuse communauté composée de la crème de la crème des
          professionnels du digital voulant monter en compétence sur le design
          d’expérience et s’en servir pour relever les grands défis du 21ᵉ
          siècle.
          <br />
          <br />
          Keep Calm & Donut Panic !
        </p>
        <div className="text-center">
          <StaticImage
            src="../images/photos/pierre-et-quentin.jpg"
            alt="photo de l'équipe"
            className="max-w-5xl mt-10 mb-10"
          />
        </div>
      </Section>

      <Section title="Nos valeurs">
        <AgencyCard
          icon={
            <StaticImage
              src="../images/icons/donut-heart.png"
              alt="donut coeur"
              className="w-40"
            />
          }
          intent="La joie"
          hook="DONUT WORRY, BE HAPPY"
        >
          <p className="mt-8">
            Chez Donut Panic, le <strong>plaisir</strong> de vivre est
            intrinsèque à notre mode de vie. Nous mettons un point d’honneur à
            valoriser la <strong>bonne humeur</strong> de chacun et à répandre
            autour de nous un état d’esprit optimiste. Notre équipe vous
            répondra toujours avec le sourire et fera tout son possible pour
            combler vos attentes et vous donner le smile.
          </p>
        </AgencyCard>
        <hr className="my-8 lg:hidden" />
        <AgencyCard
          icon={
            <StaticImage
              src="../images/icons/donut-loop.png"
              alt="donut coeur"
              className="w-40"
            />
          }
          intent="Le sens"
          hook="FILL YOUR DONUT"
        >
          <p className="mt-8">
            Le sens et <strong>l’éthique</strong> sont des valeurs fondamentales
            pour un travail dans lequel nous mettons toute notre passion. Notre
            équipe mettra tout en œuvre pour transmettre vos valeurs au travers
            de vos projets, mais surtout à leur donner du sens.
          </p>
        </AgencyCard>
        <hr className="my-8 lg:hidden" />
        <AgencyCard
          icon={
            <StaticImage
              src="../images/icons/donut-map.png"
              alt="donut coeur"
              className="w-40"
            />
          }
          intent="L'exploration"
          hook="DONUT FORGET TO DARE"
        >
          <p className="mt-8">
            La <strong>découverte</strong> et la{" "}
            <strong>recherche de nouvelles possibilités</strong> sont des
            piliers de notre démarche chez Donut Panic. Notre équipe prend à
            cœur vos projets et reste à jour sur toutes les pratiques actuelles
            du digital, afin de vous proposer des solutions adaptées et dans
            l'air du temps.
          </p>
        </AgencyCard>
        <hr className="my-8 lg:hidden" />
        <AgencyCard
          icon={
            <StaticImage
              src="../images/icons/donut-gift.png"
              alt="donut coeur"
              className="w-40"
            />
          }
          intent="La générosité"
          hook="YOU COUNT, WE DONUT"
        >
          <p className="mt-8">
            La <strong>bonté</strong> et le fait de{" "}
            <strong>donner le meilleur</strong> de soi-même sont importants et
            nous tenons à partager avec vous la recette d’un bon échange
            commercial. Notre équipe se donnera toujours à 100 % afin de
            répondre au mieux à vos attentes, tout cela dans la bienveillance et
            la bonne humeur.
          </p>
        </AgencyCard>
        <hr className="my-8 lg:hidden" />
        <AgencyCard
          icon={
            <StaticImage
              src="../images/icons/donut-cup.png"
              alt="donut coeur"
              className="w-40"
            />
          }
          intent="L'accomplissement"
          hook="KEEP CALM &
              ENJOY YOUR DONUT"
        >
          <p className="mt-8">
            La <strong>réussite</strong> est fondamentale. Afin d’obtenir des
            résultats à la hauteur de vos espérances, nous mettons tout en œuvre
            afin de pouvoir célébrer ensemble la victoire et la conquête de vos
            objectifs. Notre équipe va au front pour vos projets, avec des
            méthodes prouvées et reconnues, mais surtout avec le sourire.
          </p>
        </AgencyCard>
      </Section>

      <Section title="Nos collaborateurs" className="lg:mt-12">
        <div className="flex flex-col items-center mt-main lg:justify-evenly lg:flex-row">
          {/* Quentin */}
          <TeamMember
            front={
              <div className="flex flex-col items-center mt-9 lg:mt-0">
                <div className="flex items-center justify-center lg:w-48 lg:h-48 h-44 w-44">
                  <StaticImage
                    alt="photo quentin"
                    src="../images/photos/quentin_front.png"
                    className="rounded-full lg:w-48 lg:h-48 w-44 h-44"
                  />
                </div>
                <div className="shadow-none lg:-mt-24 lg:shadow-lg lg:w-40 lg:mb-2 rounded-2xl lg:h-72">
                  <p className="mt-4 text-xl font-bold text-center lg:text-2xl lg:mt-28">
                    Quentin
                  </p>
                  <p className="px-4 mt-4 text-base text-center lg:mt-8 lg:text-xl mb-7">
                    Entrepreneur UX&nbsp;Designer Psychologue
                  </p>
                </div>
              </div>
            }
            back={
              <div className="flex flex-col items-center mt-9 lg:mt-0">
                <div className="flex items-center justify-center lg:w-48 lg:h-48 h-44 w-44">
                  <StaticImage
                    alt="photo quentin recto"
                    src="../images/photos/quentin_back.png"
                    className="rounded-full lg:w-48 lg:h-48 w-44 h-44"
                  />
                </div>
                <div className="shadow-none lg:-mt-24 lg:shadow-lg lg:w-40 lg:mb-2 rounded-2xl lg:h-72">
                  <div className="mt-8 text-xs lg:m-3 lg:text-base lg:mt-28">
                    <p>Il ♥️ :</p>
                    <ul>
                      <li>• les chatons turbulents</li>
                      <li>• les balades en montagne</li>
                      <li>• les stinky fish</li>
                    </ul>
                  </div>
                </div>
              </div>
            }
          />
          {/* Pierre */}
          <TeamMember
            front={
              <div className="flex flex-col items-center mt-9 lg:mt-0 ">
                <div className="flex items-center justify-center lg:w-48 lg:h-48 h-44 w-44">
                  <StaticImage
                    alt="photo de pierre recto"
                    src="../images/photos/pierre_front.png"
                    className="rounded-full lg:w-48 lg:h-48 w-44 h-44"
                  />
                </div>
                <div className="shadow-none lg:-mt-24 lg:shadow-lg lg:w-40 lg:mb-2 rounded-2xl lg:h-72">
                  <p className="mt-4 text-xl font-bold text-center lg:text-2xl lg:mt-28">
                    Pierre
                  </p>
                  <p className="px-4 mt-4 text-base text-center lg:mt-8 lg:text-xl mb-7">
                    Entrepreneur UX&nbsp;Designer Psychologue
                  </p>
                </div>
              </div>
            }
            back={
              <div className="flex flex-col items-center mt-9 lg:mt-0">
                <div className="flex items-center justify-center lg:w-48 lg:h-48 h-44 w-44">
                  <StaticImage
                    alt="photo de pierre verso"
                    src="../images/photos/pierre_back.png"
                    className="rounded-full lg:w-48 lg:h-48 w-44 h-44"
                  />
                </div>
                <div className="shadow-none lg:-mt-24 lg:shadow-lg lg:w-40 lg:mb-2 rounded-2xl lg:h-72">
                  <div className="mt-8 text-xs lg:m-3 lg:text-base lg:mt-28">
                    <p>Il ♥️ :</p>
                    <ul>
                      <li>• les podcasts</li>
                      <li>• le yoga</li>
                      <li>• les roses des sables</li>
                    </ul>
                  </div>
                </div>
              </div>
            }
          />

          {/* Tara */}
          <TeamMember
            front={
              <div className="flex flex-col items-center mt-9 lg:mt-0">
                <div className="flex items-center justify-center lg:w-48 lg:h-48 h-44 w-44">
                  <StaticImage
                    alt="photo de Tara recto"
                    src="../images/photos/tara_front.png"
                    className="rounded-full lg:w-48 lg:h-48 w-44 h-44"
                  />
                </div>
                <div className="bg-white shadow-none lg:-mt-24 lg:shadow-lg lg:w-40 lg:mb-2 rounded-2xl lg:h-72">
                  <p className="mt-4 text-xl font-bold text-center lg:text-2xl lg:mt-28">
                    Tara
                  </p>
                  <p className="px-4 mt-4 text-base text-center lg:mt-8 lg:text-xl mb-7">
                    UX&nbsp;Designer Graphiste
                  </p>
                </div>
              </div>
            }
            back={
              <div className="flex flex-col items-center mt-9 lg:mt-0">
                <div className="flex items-center justify-center lg:w-48 lg:h-48 h-44 w-44">
                  <StaticImage
                    alt="photo de Tara verso"
                    src="../images/photos/tara_back.png"
                    className="rounded-full lg:w-48 lg:h-48 w-44 h-44"
                  />
                </div>
                <div className="shadow-none lg:-mt-24 lg:shadow-lg lg:w-40 lg:mb-2 rounded-2xl lg:h-72">
                  <div className="mt-8 text-xs lg:m-3 lg:text-base lg:mt-28">
                    <p>Elle ♥️ :</p>
                    <ul>
                      <li>• les feux d’artifice</li>
                      <li>• chiner en brocante</li>
                      <li>• l’ornithologie</li>
                    </ul>
                  </div>
                </div>
              </div>
            }
          />
        </div>
        <Button
          variant={ButtonVariant.primary}
          text="Contactez-nous"
          onClick={() => setShowModal(true)}
          className="mx-auto mt-8 lg:mt-10"
        />
      </Section>
    </Layout>
  );
};

export default AgencePage;
