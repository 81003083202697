// @ts-nocheck
import * as React from "react";
import { useSpring, a } from "@react-spring/web";
import { useMediaQuery } from "react-responsive";
import { twMerge } from "tailwind-merge";

interface TeamMember {
  front: React.ReactNode;
  back: React.ReactNode;
}

/**
 * TeamMember Flip card animation
 * inspired by https://codepen.io/kentaro_au/pen/MWOEGOj
 */
const TeamMember = ({ front, back }: TeamMember) => {
  const [flipped, setFlipped] = React.useState(false);
  const { transform } = useSpring({
    transform: `perspective(1000px) rotateY(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const flipCard = () => {
    if (isDesktopOrLaptop) {
      return;
    }

    setFlipped(!flipped);
  };

  const handleMouse = (forceValue: boolean) => {
    if (!isDesktopOrLaptop) {
      return;
    }

    setFlipped(forceValue);
  };

  return (
    <div
      className="mb-4 lg:w-48 w-72 lg:mb-14 h-88"
      onMouseLeave={() => handleMouse(false)}
      onMouseOver={() => handleMouse(true)}
      onClick={() => flipCard()}
    >
      <a.div
        className="absolute lg:w-48 lg:h-96 h-88 w-72 rounded-2xl shadow-card lg:shadow-none backface-hidden"
        style={{
          // comme will change utilise bcp de ressource, on tente sans
          // "will-change": "transform",
          transform,
          rotateY: "180deg",
        }}
      >
        {back}
      </a.div>
      <a.div
        className={
          "absolute lg:w-48 lg:h-96 h-88 w-72 rounded-2xl shadow-card lg:shadow-none backface-hidden"
        }
        style={{
          // "will-change": "transform",
          transform,
        }}
      >
        {front}
      </a.div>
    </div>
  );
};

export default TeamMember;
